
<div class="main-parent-meetwise-new">
         <section class="sec-1">
             <div class="row meetwise m-0">
                 <div class="col-lg-4 p-0">
                     <div class="new-login">
                         <div class="h-box dataBoxLeft">
                             <div class="login-box">
                                 <div>
                                     <img src="assets/meetwise-new/PNG/logo.png" alt="">
                                 </div>
                                 <div class="grider-wewww">
                                     <h6 class="platform-title">
                                         Super platform for communication and collaboration
                                     </h6>
                                     <p class="platform-pr">
                                         Connect smartly | Communicate easily | Collaborate efficiently
                                     </p>
                                 </div>
                             </div>
                             <div class="login-box">
                                <div>
                                    <img src="assets/meetwise-new/PNG/logo.png" alt="">
                                </div>
                                <div>
                                    <h6 class="platform-title">
                                        Super platform for communication and collaboration
                                    </h6>
                                    <p class="platform-pr">
                                        Connect smartly | Communicate easily | Collaborate efficiently
                                    </p>
                                </div>
                            </div>
                            <div class="login-box">
                                <div>
                                    <img src="assets/meetwise-new/PNG/logo.png" alt="">
                                </div>
                                <div>
                                    <h6 class="platform-title">
                                        Super platform for communication and collaboration
                                    </h6>
                                    <p class="platform-pr">
                                        Connect smartly | Communicate easily | Collaborate efficiently
                                    </p>
                                </div>
                            </div>
                            <div class="login-box">
                                <div>
                                    <img src="assets/meetwise-new/PNG/logo.png" alt="">
                                </div>
                                <div>
                                    <h6 class="platform-title">
                                        Super platform for communication and collaboration
                                    </h6>
                                    <p class="platform-pr">
                                        Connect smartly | Communicate easily | Collaborate efficiently
                                    </p>
                                </div>
                            </div>
                         </div>
                 
                         <img src="assets/meetwise-new/SVG/graphic_1.svg" class="msg-box" alt="">
                     </div>
                 </div>
                 <div class="col-lg-8 p-0">
                     <router-outlet></router-outlet>
                     <!-- <app-sub-log-in-comp></app-sub-log-in-comp> -->
                 </div>
             </div>
         </section>
     </div>
     