<div class="forBackdrop"></div>
<div class="modal-content" style="min-height: 230px; background: white; z-index: 2; position: relative; padding: 30px">

    
    <div>
        <div class="qr-sceen-box">
            <div class="name-box">
                <h1><span> Would you like to add a profile picture? </span></h1>
            </div>
        </div>
        <div class="FACE-flex" >
            <div class="imgs-img" (click)="selectedImagetoBesent(bitimg, i)" [attr.style]="selectedImageIndex == i? 'background-color: #F5F6F7': ''" *ngFor="let bitimg of bitMojisFromBack?.data; let i = index">
                <img [src]="bitimg" alt="">
                <img *ngIf="selectedImageIndex == i" src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
            </div>
          
            <div class="imgs-img-blue" style="position: relative;">
                <label for="upload-input" style="margin: 0; position: relative; height: 80px; width: 80px; ">
                  <!-- <img style="height: 80px; left: 0px; top: 0px; width: 80px; border-radius: 8px;" [ngStyle]="imageOfProfile ? { 'position': 'absolute' } : {}" [src]="imageOfProfile? imageOfProfile:'assets/meetwise-new/SVG/ic_upload.svg'" alt=""> -->
                  <img *ngIf="imageOfProfile" [src]="imageOfProfile" alt="" style="width: 80px; border-radius: 8px; height:80px">
                  <img *ngIf="!imageOfProfile" style="padding: 28px" src="assets/meetwise-new/SVG/ic_upload.svg" alt="">
                  <input type="file"  accept="image/*" (change)="selectImagePr($event)" id="upload-input" style="display:none;">
                </label>
                <img *ngIf="imageOfProfile" (click)="removeProfile()" src="/assets/images/ic_image_remove.png" class="cross" style="right: 0 !important" alt="">
              </div>
        </div>
        <div class="google-flex" style="margin-top: 30px;">
            <div></div>
            <div class="jghwe">
                <a class="back-btn" (click)="prev()">Back</a>
                <a class="Next-btn w-at" (click)="selectImage()">Next</a>
            </div>
        </div>
    </div>
</div>