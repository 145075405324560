import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  name: 'chatUser'
})
export class ChatUserPipe implements PipeTransform {

    transform(value: string) {
       return convertToNiceDate(value);
    }

}

function convertToNiceDate(time: string) {
    var date = new Date(time),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        daydiff = Math.floor(diff / 86400);

    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31)
        return '';

    return daydiff == 0 && (
        diff < 60 && "Just now" ||
        diff < 120 && convertToTime(time) ||
        diff < 3600 && convertToTime(time) ||
        diff < 7200 && convertToTime(time) ||
        diff < 86400 && convertToTime(time)) ||
        daydiff == 1 && "Yesterday" ||
        daydiff > 1 && moment(new Date(time)).format('DD MMM');
}

function convertToTime(time) {
    const seconds = Math.floor((+new Date() - +new Date(time)) / 1000);
    const intervals = {
        'day': 86400,
        'hour': 3600,
        'min': 60,
        'second': 1
      };
      let counter;
      for (const i in intervals) {
        counter = Math.floor(seconds / intervals[i]);
        if (counter > 0)
          if (counter === 1) {
            return counter + ' ' + i + ' ago'; // singular (1 day ago)
          } else {
            return counter + ' ' + i + 's ago'; // plural (2 days ago)
          }
      }
}