import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './not-found/not-found.component';

// guards
import { AuthGuard } from './services/guards/auth.guard';
import { LayoutGuard } from './services/guards/layout.guard';
import { DemoComponent } from './demo/demo.component';
import { OtpGuard } from './services/guards/otp.guard';
import { AfterLogInprocessComponent } from './after-log-inprocess/after-log-inprocess.component';
import { StaticScreensComponent } from './home/static-screens/static-screens.component';

const routes: Routes = [
  {
    path: '', redirectTo: '/home', pathMatch: 'full'
  },
  {
  path: 'demo', component:DemoComponent
  },
  {
    path: 'loggedInPend-data', loadChildren: () => import('./after-log-inprocess/after-log-in.module').then(m => m.afterLogInModule)
  },
  {
    path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    data: { breadcrumbs: 'Home', title: 'Home' },
    canActivate: [AuthGuard]
  },
  {
    path: 'login', loadChildren: () => import('./public-pages/login/login.module').then(m => m.LoginModule),
    data: { breadcrumbs: 'Login', title: 'Login' },
    canActivate: [AuthGuard]
  },
  {
    path: 'check', loadChildren: () => import('./public-pages/check/check.module').then(m => m.CheckModule),
    data: { breadcrumbs: 'check', title: 'check' }

  },
  {
    path: 'successful', loadChildren: () => import('./public-pages/successful/successful.module').then(m => m.SuccessfulModule),
    data: { breadcrumbs: 'Successful', title: 'Successful' }
  },
  {
    path: '', 
    canActivate: [LayoutGuard, OtpGuard],
    loadChildren: () => import('./layout/layout.module').then(m => m.LayoutModule)
  },
  {
    path: ':id',
    component: StaticScreensComponent
  },
  {
    path: '**', component: NotFoundComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
