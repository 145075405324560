
import { Pipe, PipeTransform } from '@angular/core';

import * as moment from 'moment';

@Pipe({
  name: 'chatDate',
  pure: true
})
export class ChatDatePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value) {
      const seconds = Math.floor((+new Date() - +new Date(value)) / 1000);
      if (seconds < 29) // less than 30 seconds ago will show as 'Just now'
        return 'Just now';

      if(Math.floor(seconds / 3600) == 0) {
        return moment(new Date(value)).format('hh:mm a');
      } else if(Math.floor(seconds / 3600) == 1) {
        return 'Yesterday';
      } else if(Math.floor(seconds / 3600) > 1) {
        return moment(new Date(value)).format('DD MMM');
      }
    }
    return value;
  }

}