import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiUrl } from 'src/app/core/apiUrl';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
  selector: 'app-static-screens',
  templateUrl: './static-screens.component.html',
  styleUrls: ['./static-screens.component.scss']
})
export class StaticScreensComponent implements OnInit {

  data = '';
  type = '';






  constructor(
    private http: HttpService, private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(data => {
      if(this.route.snapshot.params['id'] == 'refund') {
        this.type = 'refund_and_cancellation';
      } else if(this.route.snapshot.params['id'] == 'faq') {
        this.type = 'faq';
      } else if(this.route.snapshot.params['id'] == 'privacy') {
        this.type = 'privacy_policy';
      } else if(this.route.snapshot.params['id'] == 'terms') {
        this.type = 'terms_and_conditions';
      }
      this.getData();
    });
  }

  getData() {
    this.http.getData(ApiUrl.get_static_screens).subscribe(res => {
      this.data = res.data[this.type];
    }, error => {});
  }

}
