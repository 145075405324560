// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.ap
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiBaseUrl: 'https://api-v2.meetwiseapp.com/',
  apiBaseUrl: 'https://api.meetwiseapp.com/',
  // apiBaseUrl : "http://3.108.41.65:8000/",
  // apiBaseUrlForMedia : "http://43.205.233.94:8000/",
  // apiBaseUrl : "https://meetwise-dev-api.wehive.io/",


  firebase: {
    apiKey: "AIzaSyCZJ-rjxOJnQre6t01bIJ99R-4tlRByt2U",
    authDomain: "meetwise-c447a.firebaseapp.com",
    projectId: "meetwise-c447a",
    storageBucket: "meetwise-c447a.appspot.com",
    messagingSenderId: "52618727111",
    appId: "1:52618727111:web:c6e10abc6444d59272b8e2",
    measurementId: "G-NF2GDRJTZR"
  },
  ipToken: 'bc6b218bbbff6a302d60ee1eda839ad9'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
