import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { GlobalVariable } from '../core/global';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  title = '';
public onCloseModal = new Subject;

  constructor(
    private _location: Location
  ) { }

  // localstorage setvalue, clear and get value start
  getToken() {
    if (!!localStorage.getItem(GlobalVariable.tokenKey)) {
      return localStorage.getItem(GlobalVariable.tokenKey)
    } else {
      return '';
    }
  }

  errorHandler(event, image) {
    event.target.src = image;
  }

  setToken(data, id) {
    localStorage.setItem(GlobalVariable.tokenKey, data);
    localStorage.setItem(GlobalVariable.user_id, id);
  }

  WeHaveUserName



  getLocalDataUsername() {
    const promise = new Promise(
      (resolve, reject) => {
       let obj = localStorage.getItem(GlobalVariable.userDataKey)
        if (obj['user_name'] != null) {
          this.WeHaveUserName = true
        } else {
          this.WeHaveUserName= false
        }
        resolve(this.WeHaveUserName)
      }
    )
    return promise
  }


  
  setLocalData(key: string, data: any, json?: boolean) {
    localStorage.setItem(key, json ? JSON.stringify(data) : data);
  }

  getLocalData(key: string, json?: boolean) {
    let _data: any = null;
    try {
      _data = json ? JSON.parse(localStorage.getItem(key)) : localStorage.getItem(key);
      return _data;
    } catch (error) {
      if (error instanceof SyntaxError) this.clearLocalData(key);
      return null;
    }
  }

  clearAllLocalData() {
    localStorage.clear();
  }

  clearLocalData(key: string) {
    localStorage.removeItem(key);
  }
  // localstorage setvalue, clear and get value end

  goBack() {
    this._location.back();
  }

  cal(page, limit, count) {
    if (page * limit <= count) {
      return page * limit;
    } else {
      return count;
    }
  }

  makeLastDayHourMiliSeconds(data) {
    let day: any = new Date(data).getDate();
    day = day < 10 ? `0${day}` : day;
    let year = new Date(data).getFullYear();
    let month: any = new Date(data).getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    return new Date(`${month}-${day}-${year} 23:59:59`).getTime()
  }

  makeStartDayHourMiliSeconds(data) {
    let day: any = new Date(data).getDate();
    day = day < 10 ? `0${day}` : day;
    let year = new Date(data).getFullYear();
    let month: any = new Date(data).getMonth() + 1;
    month = month < 10 ? `0${month}` : month;
    return new Date(`${month}-${day}-${year} 00:00:00`).getTime();
  }

  private userProfile = new BehaviorSubject<any>(null);
  userState = this.userProfile.asObservable();
  setUserData(data) {
    this.userProfile.next(data);
  }

  private getProfileCall = new BehaviorSubject<any>(true);
  getProfileCallState = this.getProfileCall.asObservable();
  callGetProfile(data) {
    this.getProfileCall.next(data);
  }

  remameFile(file) {
    let filenameWithTimestamp = '';
    const fileName = file.name.split('.');
    // Creating filename unique by adding timestamp in original name
    fileName[0] = fileName[0].replace(/\s*/g, '').concat(String(new Date().getTime()));
    filenameWithTimestamp = fileName.join('.');
    const myNewFile = new File([file], String(filenameWithTimestamp));
    return myNewFile;
  }

  private notification = new Subject<any>();
  notificationState = this.notification.asObservable();
  setNotification(data) {
    this.notification.next(data);
  }

  checkDiff(data) {
    const date1 = new Date(data.start_date).getTime();
    const date2 = new Date(data.end_date).getTime();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  checkDiffDates(oldDate1, oldDate2) {
    const date1 = new Date(oldDate1).getTime();
    const date2 = new Date(oldDate2).getTime();
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  }

  private getDataCallSub = new Subject<any>();
  getDataState = this.getDataCallSub.asObservable();
  setDataGetState(data) {
    this.getDataCallSub.next(data);
  }

  getHostName() {
    return document.location.protocol + '//' + document.location.hostname;
  }

  tConvert(time) {
    // Check correct time format and split into components
    time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) { // If time format correct
      time = time.slice(1);  // Remove full string match value
      time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
      time[0] = +time[0] % 12 || 12; // Adjust hours
    }
    return time.join(''); // return adjusted time or original string
  }

}
