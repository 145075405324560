import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'niceDateFormatPipe',
})
export class NiceDateFormatPipe implements PipeTransform {

    transform(value: string) {
       return convertToNiceDate(value);
    }

}

function convertToNiceDate(time: string) {
    var date = new Date(time),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        daydiff = Math.floor(diff / 86400);

    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31)
        return '';

    return daydiff == 0 && (
        diff < 60 && "Today" ||
        diff < 120 && "Today" ||
        diff < 3600 && "Today" ||
        diff < 7200 && "Today" ||
        diff < 86400 && "Today") ||
        daydiff == 1 && "Yesterday" ||
        daydiff < 7 && daydiff + " days ago" ||
        daydiff < 31 && Math.ceil(daydiff / 7) + " week(s) ago";
}