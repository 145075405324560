
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgxPaginationModule } from 'ngx-pagination';


// Directives
import { AmountDirective } from './directives/amount.directive';
import { NumberOnlyDirective } from './directives/number-only.directive';
import { GoogleplaceDirective } from './directives/googleplace.directive';

import { ModalModule } from 'ngx-bootstrap/modal';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { CarouselModule } from 'ngx-bootstrap/carousel';

import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { FormatTimePipe } from './pipe/format-time.pipe';
import { QRCodeModule } from 'angularx-qrcode';
import { NiceDateFormatPipe } from './pipe/nice.pipe';

import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { LightboxModule } from 'ngx-lightbox';
import { TagInputModule } from 'ngx-chips';
import { DateFormatPipe } from './pipe/date-pipe.pipe';
import { NotificationFormatPipe } from './pipe/notification-pipe.pipe';

import { MatMenuModule } from '@angular/material/menu';
import { ChatDatePipe } from './pipe/chat-date.pipe';

import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ChatUserPipe } from './pipe/chat-user.pipe';
import { LocalSearchPipe } from './pipe/local-search.pipe';
import { AgmCoreModule } from '@agm/core';
import { DateAgoPipe } from './pipe/date-ago/date-ago.pipe';

const directives = [
  NumberOnlyDirective,
  AmountDirective,
  FormatTimePipe,
  NiceDateFormatPipe,
  GoogleplaceDirective,
  DateFormatPipe,
  ChatDatePipe,
  NotificationFormatPipe,
  ChatUserPipe,
  LocalSearchPipe
  
];

const matModule = [
  MatMenuModule
];

const imports = [
  FormsModule,
  ReactiveFormsModule,
  HttpClientModule,
  NgxPaginationModule,
  NgxIntlTelInputModule,
  QRCodeModule,
  LightboxModule,
  TagInputModule,
  InfiniteScrollModule
];

@NgModule({
  declarations: [
    ...directives
  ],
  imports: [
    ...imports,
    ...matModule,
    CommonModule,
    BsDatepickerModule.forRoot(),
    ModalModule.forRoot(),
    CarouselModule.forRoot(),
    TimepickerModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCSIFuXPQXel1splGkx5ElXoU1bL60Jn-I'
    })
  ],
  exports: [
    ...directives,
    ...imports,
    ...matModule,
    BsDatepickerModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule,
    CarouselModule,
    TimepickerModule,
    AgmCoreModule
  ],
  entryComponents: [],
  providers: []
})
export class SharedModule { }
