import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { GlobalVariable } from 'src/app/core/global';
import { UiService } from '../ui.service';
import { Observable } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap/modal';

@Injectable({
    providedIn: 'root'
})
export class OtpGuard implements CanActivate {

    constructor(
        protected router: Router,
        private uiService: UiService,
        private modalService: BsModalService
    ) { }
    canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot){ 
        let obj = this.uiService.getLocalData(GlobalVariable.userDataKey, true);
        console.log(obj['user_name']);
        console.log(obj.user_name);
        console.log(obj.social_id);
        console.log(obj['social_id']);
        if (!!this.uiService.getToken() && obj['user_name'] != null) {
            console.log('otp hitted');

            return true;
        } else if (!!this.uiService.getToken() && obj['user_name'] == null && !obj['social_id']) {
            console.log('otp hitted');

            this.router.navigate(['/loggedInPend-data']);
            return false;
        }
        else if(!!this.uiService.getToken() && obj['social_id']) {
            console.log('otp hitted');

            return true;
        }
        
            console.log('otp hitted');

            this.router.navigate(['/dashboard']);
            return false;
      }
}
