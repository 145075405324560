export const ApiUrl = {

  user_signup: 'User/user_signup',
  resend_otp: 'User/resend_otp',
  verify_otp: 'User/verify_otp',
  get_group_info: 'User/get_group_info',
  list_contacts: 'User/list_contacts',
  get_my_day_schedule: 'Schedule/get_my_day_schedule', 
  set_day_schedule: 'Schedule/set_day_schedule',
  duplicate_day_schedule: 'Schedule/duplicate_day_schedule',
  get_other_user_day_schedule: 'Schedule/get_other_user_day_schedule',
  book_user_schedule: 'Schedule/book_user_schedule',
  change_day_schedule_status: 'Schedule/change_day_schedule_status',
  list_added_polls: 'User/list_added_polls',
  delete_poll: 'User/delete_poll',
  edit_poll: 'User/edit_poll',
  share_post: 'User/share_post',
  create_poll: 'User/create_poll',
  get_poll_details: 'User/get_poll_details',
  delete_media_from_meeting: 'User/delete_media_from_meeting',
  getSuggestion_userName: 'User/get_username_suggestions',
  add_media_to_meeting: 'User/add_media_to_meeting',
  update_device_token: 'User/update_device_token',
clear_notifications: 'User/clear_notifications',
  list_home_page_data: 'User/list_home_page_data',
  start_call: 'User/start_call',
  list_posts: 'User/list_posts',
  list_favorite_posts: 'User/list_favorite_posts',
  update_post_data: 'User/update_post_data',
  make_meeting_participant_admin: 'User/make_meeting_participant_admin',
  notify_meeting_delay:'User/notify_meeting_delay',
end_call: 'User/end_call',
social_login: 'User/social_login',
  search_sponsor: 'User/search_sponsor',
  confirm_payment_event: 'User/confirm_payment_event',
  buy_now: 'User/buy_now',
  create_commercial_feed: 'User/create_commercial_feed',
  edit_commercial_feed: 'User/edit_commercial_feed',
  create_feed: 'User/create_feed',
  list_feeds: 'User/list_feeds',
  edit_feed: 'User/edit_feed',
  delete_feed: 'User/delete_feed',
  action_post: 'User/action_post',
  get_other_user_staff: 'User/get_other_user_staff',
  get_staff_listing: 'User/get_staff_listing',
  managed_staff_status: 'User/managed_staff_status',

  list_others_feeds: 'User/list_others_feeds',
  remove_post: 'User/remove_post',
  like_feed: 'User/like_feed',
  add_comment_feed: 'User/add_comment_feed',
  list_feed_comments: 'User/list_feed_comments',
  list_feed_likes: 'User/list_feed_likes',
  delete_comment_feed: 'User/delete_comment_feed',
  update_media_view_count: 'User/update_media_view_count',

  list_added_tasks: 'User/list_added_tasks',
  list_tasks_web: 'User/list_tasks_web',

  list_meetings: 'User/list_meetings',
  list_tasks: 'User/list_tasks',
  list_events: 'User/list_events',

  create_task: 'User/create_task',
  edit_task: 'User/edit_task',
  get_task_details: 'User/get_task_details',

  create_event: 'User/create_event',
  edit_event: 'User/edit_event',

  create_meeting: 'User/create_meeting',
  edit_meeting: 'User/edit_meeting',

  get_user_analytics: 'User/get_user_analytics',
  list_other_documents: 'User/list_other_documents',
  list_document_media: 'User/list_document_media',
  get_profile_count_details: 'User/get_profile_count_details',
  get_other_work_profile: 'User/get_other_work_profile',
  get_profile_details: 'User/get_profile_details',
  get_search_data: 'User/get_search_data',
  list_other_address: 'User/list_other_address',
  list_other_bank_details: 'User/list_other_bank_details',
  list_other_business_address: 'User/list_other_business_address',
  get_other_contact_details: 'User/get_other_contact_details',
  list_other_document_media: 'User/list_other_document_media',
  get_other_profile_details: 'User/get_other_profile_details',
  follow_user: 'User/follow_user',
  add_contact: 'User/add_contact',
  update_user_status: 'User/update_user_status',

  get_user_profile: 'User/get_user_profile',
  get_notification_settings: 'User/get_notification_settings',
  update_notification_settings: 'User/update_notification_settings',
  get_static_screens: 'User/get_static_screens',

  get_chat_history: 'User/get_chat_history',
  list_chats: 'User/list_chats',
  start_chat: 'User/start_chat',

  contact_us: 'Admin/contact_us',
  get_total_count: 'User/get_total_count',
  list_notifications: 'User/list_notifications',

  add_primary_phone_number: 'User/add_primary_phone_number',


  update_profile_details: 'User/update_profile_details',
  update_phone_number: 'User/update_phone_number',
  edit_bank_details: 'User/edit_bank_details',
  edit_address: 'User/edit_address',
  update_work_profile: 'User/update_work_profile',
  selectGender: 'User/get_onboarding_profile_pictures',
  add_edit_background_companies: 'User/add_edit_background_companies',
  change_event_join_request_status: 'User/change_event_join_request_status',

  change_event_status: 'User/change_event_status',
  change_meeting_status: 'User/change_meeting_status',
  get_meeting_details: 'User/get_meeting_details',
  get_event_details: 'User/get_event_details',

  answer_poll: 'User/answer_poll',
  get_calender_events: 'User/get_calender_events',
  logout: 'User/logout',

  report_group: 'User/report_group',
  clear_chat_history: 'User/clear_chat_history',
  mute_chat_notifications: 'User/mute_chat_notifications',

  list_expenses: 'User/list_expenses',
  add_expenses: 'User/add_expenses',
  edit_expenses: 'User/edit_expenses',
  delete_expense: 'User/delete_expense',

  list_notes: 'User/list_notes',
  add_notes: 'User/add_notes',
  edit_notes: 'User/edit_notes',
  delete_notes: 'User/delete_notes',


  followinglist: 'User/list_followers',
  deleteflowing: 'User/follow_user',

  upload_attachment: 'User/upload_attachment',
  remove_participants: 'User/remove_participants',

  add_participants: 'User/add_participants',

  comment_listing: 'User/list_feed_comments',
  commentDelete: 'User/delete_comment_feed',

  user_create_grouo: 'User/create_chat_group',
  group_create: 'User/create_meeting',

  getQrdata: 'User/get_qr_code',
  get_meeting_participants: 'User/get_meeting_participants',

  user_address_list: 'User/list_address',

  ipstack_api: 'http://api.ipstack.com',
  ipify: 'http://api.ipify.org/?format=json',

  update_tutorial_shown_status: 'User/update_tutorial_shown_status',
  list_blocked_users: 'User/list_blocked_users',
  create_chat_group: 'User/create_chat_group',
  block_user: 'User/block_user',
  list_unblocked_users: 'User/list_unblocked_users',
  user_company_background: 'User/background_companies_list',
  user_list_custom_details: 'User/list_custom_details',

  send_event_fields: 'User/send_event_fields',
  user_add_skills: 'User/add_skills',
  get_commercial_feed_details: 'User/get_commercial_feed_details',

  list_document_group: 'User/list_document_group',
  list_groups: 'User/list_groups',
  list_hidden_posts: 'User/list_hidden_posts',

  User_delete_address: 'User/delete_address',
  User_list_companies: 'User/list_companies',
  set_post_favorite :'User/set_post_favorite ',
  User_add_custom_details: 'User/add_custom_details',
  edit_custom_details: 'User/edit_custom_details',
  User_delete_custom_details: 'User/delete_custom_details',
  User_add_hobbies: 'User/add_hobbies',
  get_hobbies_and_skills: 'User/get_hobbies_and_skills',
  get_purchase_history: 'User/get_purchase_history',
  create_group: 'User/create_group',
  update_group: 'User/update_group',
  hide_post: 'User/hide_post',
  User_get_work_profile: 'User/get_work_profile',
  User_list_bank_details: 'User/list_bank_details',
  User_delete_bank_details: 'User/delete_bank_details',
  User_get_company_social_links: 'User/get_company_social_links',
  User_update_company_social_links: 'User/update_company_social_links',
  User_list_document_media: 'User/list_document_media',
  User_create_document_group: 'User/create_document_group',
  User_list_document_group: 'User/list_document_group',
  user_delete_document_media: 'User/delete_document_media',
  User_delete_document_group: 'User/delete_document_group',
  delete_document: 'User/delete_document',
  User_edit_document_group: 'User/edit_document_group',
  User_upload_document_media: 'User/upload_document_media',
  User_list_business_profile_feeds: 'User/list_business_profile_feeds',
  User_list_share_profile: 'User/list_share_profile',
  edit_business_share_profile: 'User/edit_business_share_profile',
  upload_document: 'User/upload_document',
  User_get_commercial_feed_reviews: 'User/get_commercial_feed_reviews',
  User_list_commercial_feed_orders: 'User/list_commercial_feed_orders',
  get_order_details_business_profile: 'User/get_order_details_business_profile',
  User_update_order_delivery_details: 'User/update_order_delivery_details',
  User_delete_follower: 'User/delete_follower',
  cancel_order: 'User/cancel_order',
  get_order_details_user_side: 'User/get_order_details_user_side',
  view_other_user_profile :"User/view_other_user_profile"
}

// /User/get_order_details_business_profile
// /User/update_order_delivery_details
// /User/change_order_status