import { Component, OnInit } from '@angular/core';
import { ApiUrl } from '../core/apiUrl';
import { HttpService } from '../services/http/http.service';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { UiService } from '../services/ui.service';

@Component({
  selector: 'app-profile-selection',
  templateUrl: './profile-selection.component.html',
  styleUrls: ['./profile-selection.component.scss']
})
export class ProfileSelectionComponent implements OnInit {

  constructor( 
    private http: HttpService,
    public _bsModalRef: BsModalRef,
    private uiService: UiService ) { }

  imageOfProfile

  public onClose: Subject<{}> = new Subject();

  bitMojisFromBack
  ngOnInit(): void {
  }

  
  async selectImagePr(event) {
    
    if (event.target.files && event.target.files.length) {
        this.selectedProfile = null  
      console.log(event.target.files);
      // console.log(this.selectedImagetoBesent);
      this.selectedImageIndex = null
      for (let index = 0; index < event.target.files.length; index++) {
        const obj = {
          pic: this.uiService.remameFile(event.target.files[index]),
          access_token: this.uiService.getToken(),
          file_name: event.target.files[index].name,
        };
        obj['file_name'] = obj['pic'].name;
        await this.uploadImage(obj);
      }
    }
  }

  uploadImage(obj, index?) {
    console.log('hitted');
    
        this.http.postData(ApiUrl.upload_attachment, obj, true).subscribe((response) => {
    console.log('hitted in function condition checked');
    

            // formArray.controls.forEach((img, i) => {
              // if (i === index) {
                const newFileSponsors = {
                  file_name: obj.pic.name,
                  url: response.data.s3Url,
                };

                this.imageOfProfile = newFileSponsors.url
                console.log(this.selectedProfile);
                
                
                // this.selectedProfile = this.imageOfProfile
                this.selectedProfile = newFileSponsors




                console.log(this.imageOfProfile);
                console.log(this.selectedProfile);
                
                
              // }
            // }
            
        });
      }
  genderS

  selectGender() {

    const obj = {
      gender: this.genderS
    }

    this.http.getData(ApiUrl.selectGender, obj).subscribe(res => {
      console.log(res);
      this.bitMojisFromBack = res;
      localStorage.setItem('gender', this.genderS)

    })
  }

  selectImage() {
    console.log(this.selectedProfile);
    
    this.onClose.next(this.selectedProfile)
    this._bsModalRef.hide()

  }
  
  
  prev() {
    this.selectedProfile = null
    this._bsModalRef.hide()
  }

  selectedProfile


  selectedImageIndex;


  selectedImagetoBesent(img, i) {
    this.removeProfile()
  
    this.selectedImageIndex = i

    let imgToSend = {
      file_name: 'img',
      url: img
    }


    this.selectedProfile = this.imageOfProfile? this.imageOfProfile: imgToSend
    console.log(this.selectedProfile);
    
  }

  removeProfile() {
    this.imageOfProfile = null;

    // this.selectedProfile = null
  }


}
