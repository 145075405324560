import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { GlobalVariable } from 'src/app/core/global';
import { UiService } from '../ui.service';

@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {

    constructor(
        protected router: Router,
        private uiService: UiService
    ) { }

    canActivate() {
        let obj = this.uiService.getLocalData(GlobalVariable.userDataKey, true);
        if (!this.uiService.getToken()) {
            console.log('auth hitted');
            
            return true;
        } else if (this.uiService.getToken() && obj['otp'] == 0) {
            console.log('auth hitted');
            
            // this.router.navigate(['/login/otp']);
            return true;
        } else if (!!this.uiService.getToken() && obj['otp'] == 1) {
            console.log('auth hitted');
            
            this.router.navigate(['/dashboard']);
            return false;
        }
        console.log('auth hitted');
        
        this.router.navigate(['/dashboard']);
        return false;
    }
    

}
