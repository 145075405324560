export const GlobalVariable = {
	GMAIL_LOGIN_KEY: '475039482691-k15g4kp34c8jb7291v8lo26sd1ugt9tg.apps.googleusercontent.com',
	SITE_NAME: "MeetWise",
	PATTERNS: {
		email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
	},
	tokenKey: 'meet_wise_admin',
	user_id: 'meet_wise_id',
	userDataKey: 'meet_wise_user_data',
	deviceIdKey: 'meet_wise_device_id',
	lengthShowCount: 95,
	lengthTitle:95,
	chattitle : 45,

}
