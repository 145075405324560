
<!-- ############### question screen new ################ -->

<!-- <div class="main-parent-meetwise-new">
    <section class="sec-1">
        <div class="row meetwise m-0"> 
            <div class="col-lg-4 p-0">
                <div class="new-login">
                    <div class="h-box">
                        <div class="login-box">
                            <div>
                                <img src="assets/meetwise-new/PNG/logo.png" alt="">
                            </div>
                            <div>
                                <h6 class="platform-title">
                                    Super platform for communication and collaboration
                                </h6>
                                <p class="platform-pr">
                                    Connect smartly | Communicate easily | Collaborate efficiently
                                </p>
                            </div>
                        </div>
                    </div>
                    <img src="assets/meetwise-new/SVG/graphic_1.svg" class="msg-box" alt="">
                </div>
            </div>
            <div class="col-lg-8 p-0">
                <div class="login-right-side">
                    <div class="login-width-form">
                        <div class="w-100">
                            <div class="qr-sceen-box">
                                <div class="name-box">
                                    <h1><span> How may we address you? </span></h1>
                                    <p>Enter your full name and create your username.</p>
                                </div>
                            </div>
                            <div class="main-qt-grid">
                                <div>
                                    <input type="text" placeholder="" class="qction-input" value="Aiden Smith">
                                </div>
                                <div class="qt-grid">
                                    <p class="qction-title">Create your unique username</p>
                                    <input type="text" placeholder="" class="qction-input" value="aiden_1924">
                                    <p class="qction-pr">Suggestion: <span class="blue-title"> amithaiden-35 </span> • <span class="blue-title"> aiden_09 </span></p>
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="ic_green_check" alt="">
                                </div>
                            </div>
                            <div class="qt-grid">
                                <div>
                                    <p class="Requirements">Requirements</p>
                                </div>
                                <div class="max-flex">
                                    <a class="img-btn"><img src="assets/meetwise-new/SVG/ic_green_check.svg" alt="">min 5 characters</a>
                                    <a class="img-btn"><img src="assets/meetwise-new/SVG/ic_alert.svg" alt="">max 16 characters</a>
                                </div>
                            </div>
                            <div class="Requir-end">
                                <a class="Next-btn w-at d-block">Next</a>
                            </div>
                        </div>
                    </div>
                    <div class="copy-flesx">
                        <p class="copy-text">Copyright © 2021MeetWise</p>
                        <div class="term-box">
                            <a class="term-main"> Terms and conditions </a>
                            <span class="term-main">|</span>
                            <a class="term-main"> Privacy policy </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div> -->

<!-- ############### Congratulations screen new ################ -->

<!-- <div class="main-parent-meetwise-3">
    <section class="sec-1">
        <div class="Congratulations-qr">
            <div class="under-box">
                <div class="logo-gird">
                    <div>
                        <img src="assets/meetwise-new/PNG/logo.png" alt="">
                    </div>
                    <p class="Congratulations">
                        Congratulations!
                        <span class="d-block">Welcome to MeetWise</span>
                    </p>
                </div>
                <div class="name-grid-position">
                    <img src="assets/meetwise-new/SVG/ic_qr_logo.svg" class="qr-no" alt="">
                    <div>
                        <img src="assets/meetwise-new/SVG/qr.png" class="qr-imq" alt="">
                    </div>
                    <div class="Aiden-name">
                        <p>Aiden Smith, Your Unique MeetWise ID is</p>
                        <h6>aiden_1924</h6>
                    </div>
                </div>
                <div class="py-4">
                    <a class="Download-bttn">Download your QR code<img src="assets/meetwise-new/SVG/ic_download_qr_code.svg" alt=""></a>
                </div>
                <div class="d-flex">
                    <a class="Next-btn w-100">Next</a>
                </div>
            </div>
        </div>
    </section>
</div> -->

<!-- ############### Select gender screen new ################ -->

<!-- <div class="main-parent-meetwise-new">
    <section class="sec-1">
        <div class="row meetwise m-0"> 
            <div class="col-lg-4 p-0">
                <div class="new-login">
                    <div class="h-box">
                        <div class="login-box">
                            <div>
                                <img src="assets/meetwise-new/PNG/logo.png" alt="">
                            </div>
                            <div>
                                <h6 class="platform-title">
                                    Super platform for communication and collaboration
                                </h6>
                                <p class="platform-pr">
                                    Connect smartly | Communicate easily | Collaborate efficiently
                                </p>
                            </div>
                        </div>
                    </div>
                    <img src="assets/meetwise-new/SVG/graphic_1.svg" class="msg-box" alt="">
                </div>
            </div>
            <div class="col-lg-8 p-0">
                <div class="login-right-side">
                    <div class="login-width-form">
                        <div class="w-100">
                            <div class="qr-sceen-box">
                                <div class="name-box">
                                    <h1><span> Please select your gender. </span></h1>
                                </div>
                            </div>
                            <div class="male-flex">
                                <div class="cantere-box">
                                    <div class="male-img">
                                        <a class="jhjwe"><img src="assets/meetwise-new/SVG/image_1.svg" alt=""></a>
                                        <p>Male</p>
                                    </div>
                                </div>
                                <div class="cantere-box">
                                    <div class="male-img">
                                        <img src="assets/meetwise-new/SVG/ic_female.svg" alt="">
                                        <p>Female</p>
                                    </div>
                                </div>
                                <div class="cantere-box">
                                    <div class="male-img">
                                        <img src="assets/meetwise-new/SVG/ic_female.svg" alt="">
                                        <p>Other</p>
                                    </div>
                                </div>
                                
                            </div>
                            <div class="google-flex">
                                <div></div>
                                <div class="jghwe"> 
                                    <a  class="back-btn">Back</a>
                                    <a class="Next-btn w-at">Next</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="copy-flesx">
                        <p class="copy-text">Copyright © 2021MeetWise</p>
                        <div class="term-box">
                            <a class="term-main"> Terms and conditions </a>
                            <span class="term-main">|</span>
                            <a class="term-main"> Privacy policy </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div> -->


<!-- ############### Set profile photo onboarding screen new ################ -->

<!-- <div class="main-parent-meetwise-new">
    <section class="sec-1">
        <div class="row meetwise m-0"> 
            <div class="col-lg-4 p-0">
                <div class="new-login">
                    <div class="h-box">
                        <div class="login-box">
                            <div>
                                <img src="assets/meetwise-new/PNG/logo.png" alt="">
                            </div>
                            <div>
                                <h6 class="platform-title">
                                    Super platform for communication and collaboration
                                </h6>
                                <p class="platform-pr">
                                    Connect smartly | Communicate easily | Collaborate efficiently
                                </p>
                            </div>
                        </div>
                    </div>
                    <img src="assets/meetwise-new/SVG/graphic_1.svg" class="msg-box" alt="">
                </div>
            </div>
            <div class="col-lg-8 p-0">
                <div class="login-right-side">
                    <div class="login-width-form">
                        <div class="w-100">
                            <div class="qr-sceen-box">
                                <div class="name-box">
                                    <h1><span> Would you like to add a profile picture? </span></h1>
                                </div>
                            </div>
                            <div class="FACE-flex">
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_1.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_2.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_3.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_4.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_5.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_6.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_7.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_8.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img">
                                    <img src="assets/meetwise-new/SVG/image_9.svg" alt="">
                                    <img src="assets/meetwise-new/SVG/ic_select_check.svg" class="acailable-ic" alt="">
                                </div>
                                <div class="imgs-img-blue">
                                    <img src="assets/meetwise-new/SVG/ic_upload.svg" alt="">
                                </div>
                            </div>
                            <div class="google-flex">
                                <div></div>
                                <div class="jghwe"> 
                                    <a  class="back-btn">Back</a>
                                    <a class="Next-btn w-at">Next</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="copy-flesx">
                        <p class="copy-text">Copyright © 2021MeetWise</p>
                        <div class="term-box">
                            <a class="term-main"> Terms and conditions </a>
                            <span class="term-main">|</span>
                            <a class="term-main"> Privacy policy </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div> -->


<!-- ##################  Don't remove ################### -->



<!-- <div class="main-parent-meetwise-4">
    <section class="sec-1">
        <div class="row m-0">
            <div class="col-lg-4 p-0">
                <div class="new-evant">
                    <div class="new-Chandigarh">
                        <input type="text" placeholder="Search..." class="Chandigarh-input" value="Sector 17, Chandigarh">
                        <img src="assets/meetwise-new/SVG/ic_download_qr_code.svg" alt="" class="filter-ix">
                    </div>
                    <div class="all-flex">   
                        <a  class="all-evnt">All</a>
                        <a  class="all-evnt">Upcoming</a>
                        <a  class="all-evnt">Past</a>
                    </div>
                    <div class="evnt-scroll">
                        <div class="Barton">
                            <div class="man-prof-flex">
                                <div class="profile-flx">
                                    <div>
                                        <img src="assets/meetwise-new/SVG/graphic_1.svg" class="gra-pro" alt="">
                                    </div>
                                    <div class="name-grider">
                                        <p>Rena Barton</p>
                                        <p>23 Sep, 2021</p>
                                    </div>
                                </div>
                                <div>
                                    <a class="chats-more"><img src="assets/MeetWiseee/svg/ic_chats_more.svg" alt=""></a>
                                </div>
                            </div>
                            <div class="design-four-box">
                                <p>UI/UX design V2 launch event</p>
                                <p>Good job team! We are going to launch our V2 design and we have an event for the same. Lorem ipsum dolor sit amet, consectetur adipiscing elit fusce pellente.</p>
                                <div class="Offline-flex">
                                    <a class="evant-links o1">Work</a>
                                    <a class="evant-links o1">Offline</a>
                                    <a class="evant-links o2">Free</a>
                                    <a class="evant-links o3">Public</a>
                                </div>
                                <div class="partici-flex">
                                    <p>Total participants: 13</p>
                                    <p>Wed, 31 Jun  •  10:00 am - 11:00 am</p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <div class="col-lg-3 p-0">
                <div class="filter-box-start">
                    <div class="Filters-head">
                        <div class="filter-flex">
                            <h6 class="filter-title">Filters</h6>
                            <a class="cloc-ic"><img src="assets/meetwise-new/PNG/close_ic.png" alt=""></a>
                        </div>
                        <div class="main-input-ranger">
                            <div class="Selec-range">
                                <input type="text" placeholder="Select date range" class="Select-date">
                                <img src="assets/new-images/empty_placeholder.png" class="clandr-position" alt="">
                            </div>
                            <div class="Events-links-box">
                                <p class="Eve-title">Events</p>
                                <div class="Upcoming-eve">
                                    <a  class="all-Events"  data-toggle="modal" data-target="#eventOnline">All</a>
                                    <a  class="all-Events" data-toggle="modal" data-target="#eventOnline1">Upcoming</a>
                                    <a  class="all-Events" data-toggle="modal" data-target="#eventOnline2">Past</a>
                                </div>
                            </div>
                        </div>
                        <div class="Events-links-box1">
                            <p class="Eve-title">Event type</p>
                            <div class="Upcoming-eve">
                                <a  class="all-Events" data-toggle="modal" data-target="#eventOnline3">All</a>
                                <a  class="all-Events" data-toggle="modal" data-target="#eventOnline4">Private</a>
                                <a  class="all-Events" data-toggle="modal" data-target="#eventOnline5">Public</a>
                            </div>
                        </div>
                        <div class="Events-links-box1">
                            <p class="Eve-title">Event mode</p>
                            <div class="Upcoming-eve">
                                <a  class="all-Events">All</a>
                                <a  class="all-Events">Online</a>
                                <a  class="all-Events">Offline</a>
                            </div>
                        </div>
                        <div class="Events-links-box1">
                            <p class="Eve-title">Event ticket</p>
                            <div class="Upcoming-eve">
                                <a  class="all-Events">All</a>
                                <a  class="all-Events">Paid</a>
                                <a  class="all-Events">Free</a>
                            </div>
                        </div>
                        <div class="Events-links-box1">
                            <p class="Eve-title pb-2">Event language</p>
                            <div class="Selec-range">
                                <input type="text" placeholder="Select date range" class="Select-date">
                                <img src="assets/images/ic_dropdown.png" class="clandr-position ic_dropdown" alt="">
                            </div>
                        </div>
                        <div class="Events-links-box1">
                            <div class="wjedhg">
                                <p class="Eve-title">Select category</p>
                                <img src="assets/images/ic_dropdown.png" class="" alt="">
                            </div>
                            <div class="Upcoming-eve">
                                <a  class="all-Events">All</a>
                                <a  class="all-Events">Conferences</a>
                                <a  class="all-Events">Trade shows</a>
                                <a  class="all-Events">Networking</a>
                                <a  class="all-Events">Workshops</a>
                                <a  class="all-Events">Charity</a>
                                <a  class="all-Events">Team building</a>
                                <a  class="all-Events">Product launch</a>
                                <a  class="all-Events">Internal corporate</a>
                            </div>
                        </div>
                        <div class="Events-links-box1">
                            <div class="wjedhg">
                                <p class="Eve-title">Type of audience</p>
                                <img src="assets/images/ic_dropdown.png" class="" alt="">
                            </div>
                            <div class="Upcoming-eve">
                                <a  class="all-Events">All</a>
                                <a  class="all-Events">Kids</a>
                                <a  class="all-Events">Ladies</a>
                                <a  class="all-Events">Senior citizen</a>
                                <a  class="all-Events">No kids</a>
                            </div>
                            <div>
                                <a class="wkjemw" >Apply</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5 p-0">
                <div class="right-position">
                    <div class="">
                        <img src="assets/meetwise-new/unnamed.webp" alt="" class="unnamed-map">
                    </div>
                    <div class="scrol-btn">
                        <div class="map-blue-btn">
                            <div class="mao-box">
                                <a class="map-btn im"><img src="assets/meetwise-new/SVG/image_1.svg" alt="">Eddie +2 is live</a>
                                <a class="map-btn im-13px"><img src="assets/meetwise-new/PNG/ic_view_count_eye.png" alt="">10K</a>
                            </div>
                            <div class="mao-box">
                                <a class="map-btn im"><img src="assets/meetwise-new/SVG/image_1.svg" alt="">Eddie +2 is live</a>
                                <a class="map-btn im-13px"><img src="assets/meetwise-new/PNG/ic_view_count_eye.png" alt="">10K</a>
                            </div>
                            <div class="mao-box">
                                <a class="map-btn im"><img src="assets/meetwise-new/SVG/image_1.svg" alt="">Eddie +2 is live</a>
                                <a class="map-btn im-13px"><img src="assets/meetwise-new/PNG/ic_view_count_eye.png" alt="">10K</a>
                            </div>
                            <div class="mao-box">
                                <a class="map-btn im"><img src="assets/meetwise-new/SVG/image_1.svg" alt="">Eddie +2 is live</a>
                                <a class="map-btn im-13px"><img src="assets/meetwise-new/PNG/ic_view_count_eye.png" alt="">10K</a>
                            </div>
                            <div class="mao-box">
                                <a class="map-btn im"><img src="assets/meetwise-new/SVG/image_1.svg" alt="">Eddie +2 is live</a>
                                <a class="map-btn im-13px"><img src="assets/meetwise-new/PNG/ic_view_count_eye.png" alt="">10K</a>
                            </div>
                            <div class="mao-box">
                                <a class="map-btn im"><img src="assets/meetwise-new/SVG/image_1.svg" alt="">Eddie +2 is live</a>
                                <a class="map-btn im-13px"><img src="assets/meetwise-new/PNG/ic_view_count_eye.png" alt="">10K</a>
                            </div>
                            <div class="mao-box">
                                <a class="map-btn im"><img src="assets/meetwise-new/SVG/image_1.svg" alt="">Eddie +2 is live</a>
                                <a class="map-btn im-13px"><img src="assets/meetwise-new/PNG/ic_view_count_eye.png" alt="">10K</a>
                            </div>
                            <div class="mao-box">
                                <a class="map-btn im"><img src="assets/meetwise-new/SVG/image_1.svg" alt="">Eddie +2 is live</a>
                                <a class="map-btn im-13px"><img src="assets/meetwise-new/PNG/ic_view_count_eye.png" alt="">10K</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</div> -->



    
<!-- <div class="modal fade" id="eventOnline1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog eventOnline" role="document">
        <div class="modal-content eventOnline">
            <div class="grid-grided">
                <div class="modal-headered">
                    <a class="" data-dismiss="modal"><img src="assets/images/ic_back.png" alt=""></a>
                    <div class="Cr-vent">
                        <h6>Create Event</h6>
                        <a>Advance Options</a>
                    </div>
                </div>
                <div class="dp-position">
                    <div class="add-banner">
                        <a class="icupload"><img src="assets/meetwise-ONE/svg/image_add_ic.svg" alt="">Add Banner</a>
                        <p class="forma">Accepted all formats upto 20MB</p>
                    </div> 
                </div>
                <div class="filter-box-in">
                    <h6>Basic info</h6>
                    <input type="text" placeholder="Event name" class="evant-filter-input">
                    <textarea name="" id="" cols="30" rows="5" class="evant-filter-input teara" placeholder="Description (optional)"></textarea>
                    <div class="input-posn">
                        <input type="text" placeholder="Select category" class="evant-filter-input">
                        <img src="assets/images/ic_dropdown.png" class="ic-dropdown" alt="">
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>When will it happen?</h6>
                    <div class="input-posn">
                        <input type="text" placeholder="" class="evant-filter-input" value="Today, 21 March, 2023">
                        <img src="assets/meetwise-ONE/svg/calender_ic.svg" class="ic-dropdown cla" alt="">
                    </div>
                    <div class="input-posn">
                        <input type="text" placeholder="" class="evant-filter-input" disabled>
                        <img src="assets/meetwise-ONE/svg/watch_ic.svg" class="ic-dropdown wth" alt="">
                        <div class="time-pic">
                            <p>12:15 pm</p>
                            <img src="assets/images/ic_dropdown.png" class="roter" alt="">
                            <p>1:15 pm</p>
                        </div>
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>Event mode</h6>
                    <div class="online-fledx">
                        <a class="online-of grn"><img src="assets/meetwise-ONE/svg/online_ic.svg" class="onofflie" alt="">Online</a>
                        <a class="online-of grn"><img src="assets/meetwise-ONE/svg/offline_ic.svg" class="onofflie" alt="">Offline</a>
                    </div>
                    <div class="input-posn">
                        <input type="text" placeholder="" class="evant-filter-input" style="cursor: pointer;" value="www.100ms.live/register?referrer=https://wwddlfgj56674ggj547" disabled>
                        <img src="assets/meetwise-ONE/svg/cir_close_ic.svg" class="ic-dropdown QWQ" alt="">
                    </div>
                    <div class="Remove-flexw"> 
                        <p class="aAudio">Audio only event</p>
                        <img src="assets/meetwise-ONE/svg/toggle_off_ic.svg" class="" alt="">
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>Event type</h6>
                    <div class="online-fledx">
                        <a class="online-of grn"><img src="assets/meetwise-ONE/svg/private_ic.svg" class="onofflie" alt="">Private</a>
                        <a class="online-of grn"><img src="assets/meetwise-ONE/svg/public_ic.svg" class="onofflie" alt="">Public</a>
                    </div>
                    <div class="Remove-flexw"> 
                        <p class="Invite">Invite participants</p>
                        <div class="three-img-posi">
                            <div class="man-flexer">
                                <img src="assets/meetwise-ONE/svg/user_img_ic.svg"  class="man-imgs" alt="">
                                <img src="assets/meetwise-ONE/svg/user_img_ic.svg"  class="man-imgs" alt="">
                                <img src="assets/meetwise-ONE/svg/user_img_ic.svg"  class="man-imgs" alt="">
                            </div>
                            <div>
                                <p class="twel-in">12</p>
                            </div>
                            <div>
                                <img src="assets/images/ic_dropdown.png" class="roter" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>Event ticket</h6>
                    <div class="online-fledx">
                        <a class="online-of grn"><img src="assets/meetwise-ONE/svg/free_ic.svg" class="onofflie" alt="">Free</a>
                        <a class="online-of grn"><img src="assets/meetwise-ONE/svg/paid_ic.svg" class="onofflie" alt="">Paid</a>
                    </div>
                </div>
                <div class="filter-box-in">
                    <a class="Create-Event-btn">Create Event</a>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" id="eventOnline" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog eventOnline" role="document">
        <div class="modal-content eventOnline">
            <div class="grid-grided">
                <div class="modal-headered">
                    <a class="" data-dismiss="modal"><img src="assets/images/ic_back.png" alt=""></a>
                    <div class="Cr-vent">
                        <h6>Create Event</h6>
                        <a>Advance Options</a>
                    </div>
                </div>
                <div class="dp-position">
                    <div class="add-banner">
                        <a class="icupload"><img src="assets/meetwise-new/SVG/ic_upload.svg" alt="">Add Banner</a>
                        <p class="forma">Accepted all formats upto 20MB</p>
                    </div> 
                </div>
                <div class="filter-box-in">
                    <h6>Basic info</h6>
                    <input type="text" placeholder="Event name" class="evant-filter-input">
                    <textarea name="" id="" cols="30" rows="5" class="evant-filter-input teara" placeholder="Description (optional)"></textarea>
                    <div class="input-posn">
                        <input type="text" placeholder="Select category" class="evant-filter-input">
                        <img src="assets/images/ic_dropdown.png" class="ic-dropdown" alt="">
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>When will it happen?</h6>
                    <div class="input-posn">
                        <input type="text" placeholder="" class="evant-filter-input" value="Today, 21 March, 2023">
                        <img  src="assets/meetwise-ONE/svg/calender_ic.svg" alt="" class="ic-dropdown cla">
                    </div>
                    <div class="input-posn">
                        <input type="text" placeholder="" class="evant-filter-input" disabled>
                        <img  src="assets/meetwise-ONE/svg/watch_ic.svg" alt="" class="ic-dropdown wth">
                        <div class="time-pic">
                            <p>12:15 pm</p>
                            <img src="assets/images/ic_dropdown.png" class="roter" alt="">
                            <p>1:15 pm</p>
                        </div>
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>Event mode</h6>
                    <div class="online-fledx">
                        <a class="online-of"><img src="assets/meetwise-ONE/svg/online_ic.svg" alt="" class="onofflie">Online</a>
                        <a class="online-of"><img src="assets/meetwise-ONE/svg/offline_ic.svg" alt="" class="onofflie">Offline</a>
                    </div>
                    <div class="input-posn">
                        <input type="text" placeholder="Event location" class="evant-filter-input" value="">
                        <img src="assets/images/ic_dropdown.png" class="ic-dropdown roter" alt="">
                    </div>
                    <div class="input-posn">
                        <input type="text" placeholder="Locate on map" class="evant-filter-input">
                        <img src="assets/meetwise-ONE/svg/location_grey_ic.svg" class="ic-dropdown wth" alt="">
                    </div>
                    <div class="input-posn">
                        <input type="text" placeholder="Tag the address" class="evant-filter-input">
                        <img src="assets/meetwise-ONE/svg/tags_ic.svg" class="ic-dropdown wth" alt="">
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>Event type</h6>
                    <div class="online-fledx">
                        <a class="online-of grn"><img src="assets/meetwise-ONE/svg/private_ic.svg" alt="" class="onofflie">Private</a>
                        <a class="online-of grn"><img src="assets/meetwise-ONE/svg/public_ic.svg" alt="" class="onofflie">Public</a>
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>Event type</h6>
                    <div class="online-fledx">
                        <a class="online-of rd"><img src="assets/meetwise-ONE/svg/free_ic.svg" alt="" class="onofflie">Free</a>
                        <a class="online-of rd"><img src="assets/meetwise-ONE/svg/paid_ic.svg" alt="" class="onofflie">Paid</a>
                    </div>
                </div>
                <div class="filter-box-in">
                    <div class="Remove-flexw"> 
                        <h6>Ticket 1</h6>
                        <p>Remove </p>
                    </div>
                    <input type="text" placeholder="Ticket name" class="evant-filter-input">
                    <input type="text" placeholder="Ticket price" class="evant-filter-input">
                    <textarea name="" id="" cols="30" rows="5" class="evant-filter-input teara" placeholder="Description (optional)"></textarea>
                    <div class="filter-box-in">
                        <h6>Add payment link</h6>
                        <input type="text" placeholder="Paste your link here…" class="evant-filter-input">
                    </div>
                    <a class="another-btn"><img src="assets/meetwise-ONE/svg/plus_ic.svg" class="onofflie" alt="">Add another ticket</a>
                </div>
                <div class="filter-box-in">
                    <a class="Create-Event-btn">Create Event</a>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" id="eventOnline2" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog eventOnline" role="document">
        <div class="modal-content eventOnline">
            <div class="grid-grided">
                <div class="modal-headered">
                    <a class="" data-dismiss="modal"><img src="assets/images/ic_back.png" alt=""></a>
                    <div class="Cr-vent">
                        <h6>Advance Options</h6>
                    </div>
                </div>
                <div class="filter-box-in">
                    <div class="Media-FLEXS">
                        <h6>Media</h6>
                        <p class="Accepted-icon-i">Accepted all formats<img src="assets/meetwise-ONE/svg/info_ic.svg" alt=""></p>
                    </div>
                    <div class="gallery-flex">
                        <div class="gallery-ic">
                            <img src="assets/meetwise-ONE/svg/gallery_grey_ic.svg" class="glry-ic" alt="">
                        </div>
                   
                    </div>
                </div>
                <div class="filter-box-in">
                    <div class="Media-FLEXS">
                        <h6>Document</h6>
                        <p class="Accepted-icon-i">Accepted all formats<img src="assets/meetwise-ONE/svg/info_ic.svg" alt=""></p>
                    </div>
                    <div class="gallery-flex">
                        <div class="gallery-ic">
                            <img src="assets/meetwise-ONE/svg/gallery_grey_ic.svg" class="glry-ic" alt="">
                        </div>
                        <div class="gallery-ic">
                            <img src="assets/meetwise-ONE/svg/gallery_grey_ic.svg" class="glry-ic" alt="">
                        </div>
                        <div class="gallery-ic">
                            <img src="assets/meetwise-ONE/svg/gallery_grey_ic.svg" class="glry-ic" alt="">
                        </div>
                        <div class="gallery-ic">
                            <img src="assets/meetwise-ONE/svg/gallery_grey_ic.svg" class="glry-ic" alt="">
                        </div>
                        <div class="gallery-ic">
                            <img src="assets/meetwise-ONE/svg/gallery_grey_ic.svg" class="glry-ic" alt="">
                        </div>
                    </div>
                </div>
                <div class="Type-of-audience">
                    <div class="">
                        <div class="Media-FLEXS nw-spc">
                            <p class="audienww">Type of audience</p>
                            <img src="assets/images/ic_dropdown.png" alt="" class="">
                        </div>
                        <div class="Upcoming-eve">
                            <a class="all-Events">All</a>
                            <a class="all-Events">Kids</a>
                            <a class="all-Events">Ladies</a>
                            <a class="all-Events">Senior citizen</a>
                            <a class="all-Events">No kids</a>
                        </div>
                        <div class="Media-FLEXS spacing">
                            <p class="wert">Audience capacity</p>
                            <div class="Audience-flex">
                                <a class="Audience-cu"><img src="assets/meetwise-ONE/svg/minus_ic.svg" alt=""></a>
                                <p class="Audience-title">0</p>
                                <a class="Audience-cu"><img src="assets/meetwise-ONE/svg/add_ic.svg" alt=""></a>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="Type-of-audience">
                    <div class=""> add
                        <div class="Media-FLEXS nw-spc">
                            <p class="audienww">Food choice</p>
                            <img src="assets/images/ic_dropdown.png" alt="" class="">
                        </div>
                        <div class="Upcoming-eve">
                            <a class="all-Events">Vegetarian</a>
                            <a class="all-Events">Non-Vegetarian</a>
                            <a class="all-Events">Vegan</a>
                            <a class="all-Events">Other</a>
                        </div>
                    </div>
                </div>
                <div class="Type-of-audience">
                    <div class="">
                        <div class="Media-FLEXS nw-spc">
                            <p class="audienww">Drink choice</p>
                            <img src="assets/images/ic_dropdown.png" alt="" class="">
                        </div>
                        <div class="Upcoming-eve">
                            <a class="all-Events">Juices</a>
                            <a class="all-Events">Soft Drinks</a>
                            <a class="all-Events">Alcoholic</a>
                            <a class="all-Events">Smoothie</a>
                            <a class="all-Events">Mix drinks</a>
                            <a class="all-Events">Other</a>
                        </div>
                    </div>
                </div>
                <div class="Type-of-audience">
                    <div class="">
                        <div class="Media-FLEXS nw-spc">
                            <p class="audienww">Dress code</p>
                            <img src="assets/images/ic_dropdown.png" alt="" class="">
                        </div>
                        <div class="Upcoming-eve">
                            <a class="all-Events">Formals</a>
                            <a class="all-Events">Semi Formals</a>
                            <a class="all-Events">Casuals</a>
                            <a class="all-Events">Party wear</a>
                            <a class="all-Events">Traditional</a>
                            <a class="all-Events">Other</a>
                        </div>
                    </div>
                </div>
                <div class="filter-box-in gap-input">
                    <div class="input-posn">
                        <input type="text" placeholder="Alerts" class="evant-filter-input">
                        <img src="assets/images/ic_dropdown.png" class="ic-dropdown roter" alt="">
                    </div>
                    <div class="input-posn">
                        <input type="text" placeholder="Additional settings" class="evant-filter-input">
                        <img src="assets/images/ic_dropdown.png" class="ic-dropdown roter" alt="">
                    </div>
                    <div class="input-posn">
                        <input type="text" placeholder="Select event language" class="evant-filter-input">
                        <img src="assets/images/ic_dropdown.png" class="ic-dropdown roter" alt="">
                    </div>
                </div>
                <div class="filter-box-in">
                    <h6>Add sponsor</h6>
                    <div class="sponsor-fles">
                        <div class="sponsor-img">
                            <img src="assets/meetwise-ONE/svg/gallery_grey_ic.svg" alt="" class="glry-ic">
                        </div>
                        <div class="w-100"> 
                            <input type="text" placeholder="Sponsor website link" class="evant-filter-input">
                        </div>
                    </div>
                    <div>
                            
                        

                    </div>
                    <div>
                        <a class="another-btn"><img src="assets/meetwise-ONE/svg/plus_ic.svg" alt="" class="onofflie">Add more</a>
                    </div>
                </div>
                
                <div class="filter-box-in">
                    <a class="Create-Event-btn">Create Event</a>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" id="eventOnline3" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog Eventtickets" role="document">
        <div class="modal-content Eventtickets">
            <div class="Event-tickets-grid">
                <div class="Cr-vent">
                    <h6>Book Ticket</h6>
                    <a class="closeic-img" data-dismiss="modal"><img src="assets/meetwise-ONE/svg/close_ic.svg" alt=""></a>
                </div>
                <div class="wefeee">
                    <div class="mainww-grid">
                        <div class="Book-title">
                            <p>Phase 1</p>
                            <p>₹ 700</p>
                        </div>
                        <div>
                            <a class="Book-btn">Book</a>
                        </div>
                    </div>
                    <div class="Book-listed">
                        <p> • This seating is behind platinum seating.</p>
                        <p>• Seating is on first come first.</p>
                    </div>
                </div>
                <div class="wefeee">
                    <div class="mainww-grid">
                        <div class="Book-title">
                            <p>Phase 1</p>
                            <p>₹ 700</p>
                        </div>
                        <div>
                            <a class="Book-btn">Book</a>
                        </div>
                    </div>
                    <div class="Book-listed">
                        <p> • This seating is behind platinum seating.</p>
                        <p>• Seating is on first come first.</p>
                    </div>
                </div>
                <div class="wefeee">
                    <div class="mainww-grid">
                        <div class="Book-title">
                            <p>Phase 1</p>
                            <p>₹ 700</p>
                        </div>
                        <div>
                            <a class="Book-btn">Book</a>
                        </div>
                    </div>
                    <div class="Book-listed">
                        <p> • This seating is behind platinum seating.</p>
                        <p>• Seating is on first come first.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->


<!-- <div class="modal fade" id="eventOnline4" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog Eventtickets" role="document">
        <div class="modal-content Eventtickets">
            <div class="Event-tickets-grid">
                <div class="Cr-vent">
                    <h6>Participants (8)</h6>
                    <a class="closeic-img" data-dismiss="modal"><img src="assets/meetwise-ONE/svg/close_ic.svg" alt=""></a>
                </div>
                <div class="all-flex">
                    <a class="all-evnt">All</a>
                    <a class="all-evnt">Accepted</a>
                    <a class="all-evnt">Rejected</a>
                    <a class="all-evnt">Pending</a>
                </div>
                <div class="more-icers">
                    <div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p>Organizer</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p class="oorens">Pending</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p class="greened">Accepted</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p class="redr">Rejected</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p>Organizer</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div><div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p>Organizer</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p>Organizer</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p>Organizer</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="more-fles">
                        <div>
                            <img src="assets/meetwise-ONE/svg/user_img_ic.svg" class="ewewef" alt="">
                        </div>
                        <div class="Eddie-titlw">
                            <div class="Organizer-grid">
                                <p>Eddie Burns</p>
                                <p>Organizer</p>
                            </div>
                            <div>
                                <img src="assets/Meetwiseassets2/svg/more_ic.svg" class="more-ix" alt="">
                            </div>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    </div>
</div> -->


<div class="modal fade" id="eventOnline5" tabindex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true">
    <div class="modal-dialog Eventtickets" role="document">
        <div class="modal-content Eventtickets">
            <div class="Event-tickets-grid">
                <div class="Cr-vent">
                    <h6></h6>
                    <a class="closeic-img" data-dismiss="modal"><img src="assets/meetwise-ONE/svg/close_ic.svg" alt=""></a>
                </div>
                <div class="text-center">
                    <div class="cntr">
                        <img src="assets/meetwise-ONE/svg/share_purple_ic.svg" alt="">
                    </div>
                    <div class="Sharemedia">
                        <p>Share media!</p>
                        <p>Where you want to share this image?</p>
                    </div>
                    <div class="share-flex">
                        <a class="share-btn3">MeetWise Feed</a>
                        <a class="share-btn3">MeetWise Poll</a>
                        <a class="share-btn3 Other">Other</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- ################### CALL SCREEN DONT DLT ################## -->

<div class="main-parent-meetwise-4">
    <section class="sec-1">
        <div class="width-controler">
            <div class="">
                <div class="Calls-felxer">
                    <div>
                        <p class="Calls-title">Calls</p>
                    </div>
                    <div class="main-imgs-indot">
                        <img src="assets/meetwise-ONE/svg/filter_ic 2.svg" alt="" class="chat-dots">
                        <img src="assets/meetwise-ONE/svg/search_ic.svg" alt="" class="chat-dots">
                        <img src="assets/Meetwiseassets2/svg/more_ic.svg" alt="" class="chat-dots">
                    </div>
                </div>
                <div class="main-table-1">
                    <table class="w-100">
                        <tr class="main-head-row">
                            <td class="main-head-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>S no.</p>
                                </div>
                            </td>
                            <td class="main-head-titles">Name</td>
                            <td class="main-head-titles">Date & Time</td>
                            <td class="main-head-titles">Status</td>
                            <td class="main-head-titles">Call type</td>
                        </tr>
                        <tr class="main-head-row1">
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>1</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/group_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">Today  •  12:02 PM</td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/incomimg_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/video_ic.svg" class="csr-p" alt="">
                                    <p></p>
                                </div>
                            </td>
                        </tr>
                        <tr class="main-head-row1">
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>1</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/group_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">Today  •  12:02 PM</td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/Outgoing_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/call_ic.svg" class="csr-p" alt="">
                                    <p></p>
                                </div>
                            </td>
                        </tr>
                        <tr class="main-head-row1">
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>1</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/group_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">Today  •  12:02 PM</td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/missed_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/call_ic.svg" class="csr-p" alt="">
                                    <p></p>
                                </div>
                            </td>
                        </tr>
                        <tr class="main-head-row1">
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>1</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/group_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">Today  •  12:02 PM</td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/Outgoing_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/call_ic.svg" class="csr-p" alt="">
                                    <p></p>
                                </div>
                            </td>
                        </tr>
                        <tr class="main-head-row1">
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>1</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/group_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">Today  •  12:02 PM</td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/incomimg_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/video_ic.svg" class="csr-p" alt="">
                                    <p></p>
                                </div>
                            </td>
                        </tr>
                        <tr class="main-head-row1">
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>1</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/group_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">Today  •  12:02 PM</td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/Outgoing_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/call_ic.svg" class="csr-p" alt="">
                                    <p></p>
                                </div>
                            </td>
                        </tr>
                        <tr class="main-head-row1">
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>1</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/group_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">Today  •  12:02 PM</td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/missed_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex"> 
                                    <img src="assets/meetwise-ONE/svg/call_ic.svg" class="csr-p" alt="">
                                    <p></p>
                                </div>
                            </td>
                        </tr>
                        <tr class="main-head-row1">
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/check_grey_ic.svg" alt="">
                                    <p>1</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/group_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">Today  •  12:02 PM</td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/Outgoing_ic.svg" alt="">
                                    <p>MeetWise launch</p>
                                </div>
                            </td>
                            <td class="main-small-titles">
                                <div class="srial-flex">
                                    <img src="assets/meetwise-ONE/svg/call_ic.svg" class="csr-p" alt="">
                                    <p></p>
                                </div>
                            </td>
                        </tr>
                    </table>
                </div>
            </div>
        </div>
    </section>
</div>
