import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({providedIn: 'root'}) export class logInService {

         loginSub = new Subject();

         sendData(data) {
                  this.loginSub.next(data)
         }

}