import { BrowserModule, } from '@angular/platform-browser';
import { NgModule } from '@angular/core';


import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';

// components
import { AppComponent } from './app.component';
import { LoaderComponent } from './loader/loader.component';

// interceptors
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { ErrorInterceptor } from './services/interceptors/error.interceptor';
import { LoaderInterceptor } from './services/interceptors/loader.interceptor';
import { JwtInterceptor } from './services/interceptors/jwt.interceptor';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

// firebase
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireModule } from '@angular/fire';
import { NotFoundComponent } from './not-found/not-found.component';
import { DemoComponent } from './demo/demo.component';
import { AfterLogInprocessComponent } from './after-log-inprocess/after-log-inprocess.component';

import { GlobalVariable } from './core/global';
import { SocialAuthServiceConfig, GoogleLoginProvider, SocialLoginModule } from 'angularx-social-login';
import { SharedModule } from "./shared/shared.module";
import { ProfileSelectionComponent } from './profile-selection/profile-selection.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { ChatDetailComponent } from './layout/chat/chat-detail/chat-detail.component';

// import { SocialLoginComponent } from './social-login/social-login.component';
// import { provideConfig } from './services/social-login-service';




@NgModule({
    declarations: [
        AppComponent,
        LoaderComponent,
        NotFoundComponent,
        DemoComponent,
        AfterLogInprocessComponent,
        ProfileSelectionComponent,
    ],
    providers: [
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false,
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(GlobalVariable.GMAIL_LOGIN_KEY)
                    }
                ],
                onError: (err) => {
                    console.error(err);
                }
            } as SocialAuthServiceConfig,
        },
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }
    ],
    bootstrap: [AppComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        SocialLoginModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        SharedModule,
        NgbModule
    ]
})
export class AppModule { }
