import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'notificationFormat',
})
export class NotificationFormatPipe implements PipeTransform {

    transform(value: string) {
        return convertToNiceDate(value);
    }

}

function convertToNiceDate(time: string) {
    var datePipe = new DatePipe("en-US");

    var date = new Date(time),
        diff = (((new Date()).getTime() - date.getTime()) / 1000),
        daydiff = Math.floor(diff / 86400);

    if (daydiff == -1) {
        daydiff = 0;
    }

    if (isNaN(daydiff) || daydiff < 0 || daydiff >= 31)
        return '';

    return daydiff == 0 && (
        diff < 60 && "Today" ||
        diff < 120 && "Today" ||
        diff < 3600 && "Today" ||
        diff < 7200 && "Today" ||
        diff < 86400 && "Today") ||
        daydiff == 1 && "Yesterday" ||
        daydiff > 1 && daydiff > 1 && datePipe.transform(new Date(time), 'dd MMMM yyyy');

}
