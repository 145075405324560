import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { logInService } from '../public-pages/login/logIn.services';
import { HttpService } from '../services/http/http.service';
import { UiService } from '../services/ui.service';
declare var $: any
@Component({
  selector: 'app-after-log-inprocess',
  templateUrl: './after-log-inprocess.component.html',
  styleUrls: ['./after-log-inprocess.component.scss']
})
export class AfterLogInprocessComponent implements OnInit {

  show = false;
  countryCode = 'IN';
  qrData: any;
  myUrl: string;

  device_name = '';
  location = '';

  constructor(
    private fb: FormBuilder, private http: HttpService,
    private router: Router, private uiService: UiService, private deviceService: DeviceDetectorService, private logInServ: logInService
  ) { }

  ngOnInit() {
    let data = this.deviceService.getDeviceInfo();
    this.device_name = data.os;
    this.myUrl = location.href;
    this.setBannerSlider();
  }



  setBannerSlider() {
    $('.dataBoxLeft').removeClass('slick-initialized slick-slider');
    setTimeout(() => {
      $('.dataBoxLeft').slick({
        arrows: false,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 200000,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      });
    }, 100);
  }

}
